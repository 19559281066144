import Image from "next/image";
import styles from "../styles/Footer.module.sass";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.logo_line}>
        <span>Clients</span>
        <Image src="/clients-online-logo.png" width={32} height={32} alt="clients-online" />
        <span>Online</span>
      </div>
    </footer>
  );
}
